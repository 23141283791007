<template>
  <div>
    <el-form ref="baseformref" :model="baseform" label-width="180px">
      <el-form-item :label="$t('cs.wzmc')" prop="sitename">
        <el-input v-model="baseform.sitename"></el-input>
      </el-form-item>

      <el-form-item :label="$t('cs.kfdz')" prop="service_url">
        <el-input v-model="baseform.service_url"></el-input>
      </el-form-item>

      <!-- <el-form-item label="下载地址" prop="downloadurl">
        <el-input v-model="baseform.downloadurl"></el-input>
      </el-form-item> -->

      <!-- <el-form-item label="Logo图标" prop="logoimg">
        <el-upload
          class="avatar-uploader"
          :action="weburl + '/admin/user/uploadimg'"
          :show-file-list="false"
          :headers="headerobj"
          :on-success="uploadsfb"
        >
          <img
            v-if="baseform.logoimg"
            :src="baseform.logoimg"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item> -->

      <el-form-item :label="$t('cs.lxmjdz')" prop="sellurl">
        <el-input v-model="baseform.sellurl" type="number"></el-input>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao')" prop="notice">
        <el-input type="textarea" v-model="baseform.notice"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(香港)'" prop="notice">
        <el-input type="textarea" v-model="baseform.notice_hk"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(美国)'" prop="notice">
        <el-input type="textarea" v-model="baseform.notice_en"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>

      <!-- <el-form-item :label="$t('cs.gonggao') + '(西班牙)'" prop="notice">
        <el-input type="textarea" v-model="baseform.notice_spa"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(法国)'" prop="notice_fr">
        <el-input type="textarea" v-model="baseform.notice_fra"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(韩国)'" prop="notice_it">
        <el-input type="textarea" v-model="baseform.notice_kor"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(日本)'" prop="notice_ru">
        <el-input type="textarea" v-model="baseform.notice_jp"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item>
      <el-form-item :label="$t('cs.gonggao') + '(泰国)'" prop="notice_ru">
        <el-input type="textarea" v-model="baseform.notice_th"></el-input>
        <el-alert :title="$t('cs.ggts')" type="error" :closable="false">
        </el-alert>
      </el-form-item> -->

      <el-form-item :label="$t('cs.wzsfgb')" prop="isclosed">
        <el-radio-group v-model="baseform.isclosed">
          <el-radio label="1">{{ $t('shi') }}</el-radio>
          <el-radio label="0">{{ $t('fou') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="reset">{{ $t('chongzhi') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseform: {
        sitename: '',
        service_url: '',
        downloadurl:'',
        notice: '',
        notice_en: '',
        notice_hk: '',
        notice_fra: '',
        notice_spa: '',
        notice_kor: '',
        notice_jp: '',
        notice_th: '',
        isclosed: '',
        sellurl: '',
        logoimg:''
      },
      headerobj: {
        Authorization: window.sessionStorage.getItem('token')
      }
    }
  },
  created() {
    this.getbase()
  },
  methods: {
    uploadsfb(res) {
      this.baseform.logoimg = res.data.url
    },
    async getbase() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.get('/admin/config/baseconfig')
      if (data) {
        if (data.code === 200) {
          this.baseform = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交
    async onSubmit() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-main')
      })
      const { data } = await this.$http.post(
        '/admin/config/baseconfig',
        this.baseform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
          // this.getbase()
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 重置
    reset() {
      this.getbase()
    }
  }
}
</script>

<style lang="less" scoped>
.el-alert {
  margin-top: 15px;
}
</style>
